import validation from 'vee-validate/dist/locale/en';

export default {
  validations: validation.messages,
  languageNames: {
    en: 'English',
    de: 'German',
    es: 'Spanish',
    it: 'Italian',
    fr: 'French',
    ro: 'Romanian',
    pl: 'Polish',
    ru: 'Russian',
    ar: 'Arabic',
    tr: 'Turkish'
  },
  fields: {
    email: 'Email',
    password: 'Password',
    verification: 'Verification Code',
    question: 'Question',
    name: 'Name',
    kvkk: 'Privacy Notice',
    kvkk2: 'Explicit Consent',
    country: 'Country'
  },
  stage: {
    languages: 'Stream Language'
  },
  login: {
    title: 'Login',
    login: 'Login',
    email_label: 'Email Address',
    email_placeholder: 'Your registered email address',
    password_label: 'Password',
    name_label: 'Name',
    terms_accept: 'I acknowledge that I have read and agreed to the {0}.',
    kvkk_doc: 'Privacy Notice',
    if_not_registered: 'If you have not registered yet,',
    click_to_register: 'click here to register now.'
  },
  cookie: {
    info:
      'Netflix uses cookies for the website to function and to collect information about your browsing activities which we use to analyse your use of the website.',
    more: 'Learn more about our use of cookies'
  },
  phoneSupportBox: {
    havingTrouble:
      'If you are having connection or login problems, you can get support from {0}.'
  },
  verification: {
    title: 'Verification',
    info:
      'We have sent you an e-mail with the verification code. Please continue by entering the code in the field below.',
    label: 'Verification Code',
    verify: 'Verify',
    Unauthorized: 'The verification code you entered is invalid.',
    not_received: 'Not received your code?',
    send_again: 'Send Again'
  },
  tabs: {
    camera: 'Together On-Air',
    questions: 'Q&A',
    polls: 'Polls'
  },
  questions: {
    upfront: 'Ask your questions upfront',
    questions: 'questions',
    enter_question: 'Write your question here',
    send: 'Send',
    latest: 'Latest',
    popular: 'Popular',
    no_questions:
      'No questions were asked yet. You can use the section above to ask the first question.',
    ask_anonymously: 'Ask anonymously?',
    sent_message: 'Your question has been received.'
  },
  auth: {
    logout: 'Logout'
  },
  poll: {
    voting_closed: 'Voting Closed',
    sent: 'Sent',
    send: 'Send',
    no_polls: 'There are no active polls at the moment.',
    edit_response: 'Edit Response'
  },
  exhibition: {
    title: 'Exhibition',
    foyer: 'Foyer'
  },
  janus: {
    NotAllowedError: 'You are not allowed to access camera and mic.',
    wrong_room: 'No such room could be found or capacity is full.',
    room_destroyed: 'Room destroyed.',
    no_webcam: 'Camera or Mic are not found or not allowed in your device.',
    consent: 'Please give the necessary permission for the camera.'
  },
  errors: {
    USER_NOT_FOUND:
      'Email address can not be found. Please be sure that you trying to sign in with your registered email address.',
    OTP_RATE_LIMIT:
      'Before sending another sign in code, please wait at least two minutes.',
    INVALID_OTP: 'The sign in code that you entered is not valid.',
    OTP_TIMEOUT:
      'Your sign in code has been timed out. Please request another one.',
    WRONG_PASSWORD: 'The password you entered is not valid.'
  },
  webrtc: {
    live: 'LIVE',
    camera: 'Camera',
    mic: 'Microphone',
    retry: 'Try Again',
    show: 'Show My Camera',
    publish_info:
      'You are not yet broadcasting. Please use the button below to start broadcasting your camera image to the stage.',
    hide: 'Hide My Camera',
    hide_info:
      'Your camera image is currently being broadcast on the stage. Please use the button below to stop broadcasting.',
    err_not_found: 'Camera or Mic are not found or not allowed in your device.',
    err_not_readable: 'Camera or Mic is being used by some other process.',
    err_overconstrained:
      'There is no device found that fits your video and audio constraints. You may change video and audio constraints',
    err_not_allowed: 'You are not allowed to access camera and mic.',
    err_type: 'Video/Audio is required.',
    err_screen_share: 'You are not allowed to access screen share.',
    err_websocket: 'WebSocket connection is disconnected.',
    err_connection: 'Disconnected.',
    err_republish: 'Reconnecting...',
    cancel: 'Cancel',
    close: 'Close',
    join_room: 'Join video broadcast',
    join: 'Join',
    visitors: 'Visitors',
    representatives: 'Representatives',
    no_camera: 'I do not have a camera'
  },
  email: {
    subject: 'Your verification code to login',
    hello: 'Hello!',
    user: 'User',
    dear_user: 'Dear %{user},',
    info_action:
      'We received a request to login to %{appName} using this email address. If you want to login with your %{mail} address, you can enter the code on the verification screen.',
    login: 'Login',
    or_code: 'or enter the code',
    ignore: "If you haven't tried to log in, you can ignore this email.",
    thank_you: 'Thank you.'
  },
  support: {
    EntryPointTagLine: 'Support',
    WelcomeMessage: 'Welcome to customer service',
    PredefinedChatMessageAuthorName: 'Bot',
    PredefinedChatMessageBody: 'Hi there! How can we help you today?',
    InputPlaceHolder: 'Type message',
    anonymous: 'Anonymous',
    Today: 'Today',
    Read: 'Read',
    MessageCanvasTrayContent:
      'Thanks for chatting with us! <br> If you have any more questions please reach out to us again.',
    MessageCanvasTrayButton: 'Start new chat'
  },
  broadcast: {
    soon: 'Our broadcast will start soon.',
    continue: 'Our boradcast will continue.',
    ended: 'Our broadcast has ended.'
  },
  welcome: {
    panelists: 'Panelists',
    proceed: 'Proceed',
    popup:
      "We are delighted to have you join us for this virtual panel with pioneering Arab women filmmakers as we celebrate their work and discuss their views on the opportunities, challenges and learnings along the way. We're excited to involve you in this important discussion."
  },
  countdown: {
    days: 'DAYS',
    hours: 'HRS',
    minutes: 'MINS',
    seconds: 'SECS'
  }
};
