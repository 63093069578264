import Vue from 'vue';
import App from './App.vue';
import PortalVue from 'portal-vue';
import vueAwesomeCountdown from 'vue-awesome-countdown';

import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import axios from './plugins/axios';
import { configureTranslationsForValidation } from './plugins/validation';
import './plugins/meta';
import './plugins/moment';
import './plugins/socket';

configureTranslationsForValidation({ i18n });
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

Vue.use(PortalVue);
Vue.use(vueAwesomeCountdown, 'vac');

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');
